import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';

// Get the base URL based on environment
const BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://www.ccroindia.in'
  : 'http://localhost:3000';

interface SendEmailResponse {
  success: boolean;
  message: string;
}

const RESET_ATTEMPT_LIMIT = 3; // Maximum number of attempts
const RESET_TIMEOUT_MINUTES = 60; // Timeout in minutes

export const sendResetPasswordEmail = async (email: string): Promise<SendEmailResponse> => {
  try {
    // Check rate limiting in Firestore
    const resetAttemptsRef = doc(db, 'passwordResetAttempts', email);
    const resetAttemptsDoc = await getDoc(resetAttemptsRef);
    
    const now = new Date();
    const resetData = resetAttemptsDoc.data();
    
    if (resetData) {
      const lastAttempt = resetData.lastAttempt.toDate();
      const attempts = resetData.attempts || 0;
      
      // If within timeout period and exceeded attempts
      if (attempts >= RESET_ATTEMPT_LIMIT && 
          (now.getTime() - lastAttempt.getTime()) < (RESET_TIMEOUT_MINUTES * 60 * 1000)) {
        const minutesLeft = Math.ceil(
          (RESET_TIMEOUT_MINUTES - (now.getTime() - lastAttempt.getTime()) / (60 * 1000))
        );
        return {
          success: false,
          message: `Too many reset attempts. Please try again in ${minutesLeft} minutes.`
        };
      }
      
      // Reset attempts if timeout has passed
      if ((now.getTime() - lastAttempt.getTime()) >= (RESET_TIMEOUT_MINUTES * 60 * 1000)) {
        await setDoc(resetAttemptsRef, {
          attempts: 1,
          lastAttempt: serverTimestamp()
        });
      } else {
        // Increment attempts
        await setDoc(resetAttemptsRef, {
          attempts: attempts + 1,
          lastAttempt: serverTimestamp()
        }, { merge: true });
      }
    } else {
      // First attempt
      await setDoc(resetAttemptsRef, {
        attempts: 1,
        lastAttempt: serverTimestamp()
      });
    }

    const auth = getAuth();

    // Send Firebase password reset email with custom template
    await sendPasswordResetEmail(auth, email, {
      url: `${BASE_URL}/reset-password`,
      handleCodeInApp: false
    });

    // Store reset attempt in Firestore
    const userRef = doc(db, 'passwordResetRequests', email);
    await setDoc(userRef, {
      email,
      requestedAt: serverTimestamp(),
      status: 'sent'
    }, { merge: true });

    return {
      success: true,
      message: 'Password reset instructions have been sent to your email. Please check your inbox and spam folder.'
    };
  } catch (error: any) {
    console.error('Error sending reset email:', error);
    
    if (error.code === 'auth/invalid-email') {
      return {
        success: false,
        message: 'Please enter a valid email address.'
      };
    }

    if (error.code === 'auth/user-not-found') {
      return {
        success: false,
        message: 'No account found with this email address.'
      };
    }

    if (error.code === 'auth/too-many-requests') {
      return {
        success: false,
        message: 'Too many requests. Please try again later.'
      };
    }

    // Log detailed error for debugging
    console.error('Detailed error:', {
      code: error.code,
      message: error.message,
      details: error
    });

    return {
      success: false,
      message: 'Failed to send reset email. Please try again later.'
    };
  }
};
