import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaQuestionCircle, FaEnvelope, FaPhone, FaComment, FaSearch } from 'react-icons/fa';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../../../config/firebase';
import { toast } from 'react-hot-toast';

const faqData = [
  {
    question: 'How do I reset my password?',
    answer: 'You can reset your password by clicking the "Forgot Password" link on the login page. Follow the instructions sent to your email to create a new password.'
  },
  {
    question: 'How can I update my profile information?',
    answer: 'Go to the User Details page in your dashboard. There you can edit your personal information, change your profile picture, and update your preferences.'
  },
  {
    question: 'What payment methods do you accept?',
    answer: 'We accept all major credit cards (Visa, MasterCard, American Express) and PayPal. All payments are processed securely through our payment gateway.'
  },
  {
    question: 'How can I cancel my subscription?',
    answer: 'You can cancel your subscription at any time from the User Details page. Note that you will continue to have access to your account until the end of your current billing period.'
  },
  {
    question: 'How do I download my membership certificate?',
    answer: 'Visit the Downloads page in your dashboard. There you will find your membership certificate along with other important documents available for download.'
  }
];

const Support = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [ticketSubject, setTicketSubject] = useState('');
  const [ticketMessage, setTicketMessage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('general');

  const filteredFAQs = faqData.filter(
    faq =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSubmitTicket = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const user = auth.currentUser;
      if (!user) {
        toast.error('You must be logged in to submit a ticket');
        return;
      }

      const ticketData = {
        userId: user.uid,
        userEmail: user.email,
        subject: ticketSubject,
        message: ticketMessage,
        category: selectedCategory,
        status: 'open',
        createdAt: serverTimestamp()
      };

      await addDoc(collection(db, 'supportTickets'), ticketData);

      toast.success('Support ticket submitted successfully');
      setTicketSubject('');
      setTicketMessage('');
      setSelectedCategory('general');
    } catch (error) {
      console.error('Error submitting support ticket:', error);
      toast.error('Failed to submit support ticket');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8"
    >
      {/* FAQ Section */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
          Frequently Asked Questions
        </h2>

        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search FAQs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white focus:border-blue-500 focus:ring-blue-500"
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>

        <div className="space-y-4">
          {filteredFAQs.map((faq, index) => (
            <motion.div
              key={index}
              initial={false}
              className="border-b border-gray-200 dark:border-gray-700 pb-4"
            >
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2 flex items-center">
                <FaQuestionCircle className="w-5 h-5 text-blue-500 mr-2" />
                {faq.question}
              </h3>
              <p className="text-gray-600 dark:text-gray-300 ml-7">
                {faq.answer}
              </p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Contact Options */}
      <div className="grid md:grid-cols-3 gap-6 mb-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 text-center">
          <FaEnvelope className="w-8 h-8 text-blue-500 mx-auto mb-4" />
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
            Email Support
          </h3>
          <p className="text-gray-600 dark:text-gray-300">
            support@example.com
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 text-center">
          <FaPhone className="w-8 h-8 text-blue-500 mx-auto mb-4" />
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
            Phone Support
          </h3>
          <p className="text-gray-600 dark:text-gray-300">
            +1 (555) 123-4567
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 text-center">
          <FaComment className="w-8 h-8 text-blue-500 mx-auto mb-4" />
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
            Live Chat
          </h3>
          <p className="text-gray-600 dark:text-gray-300">
            Available 24/7
          </p>
        </div>
      </div>

      {/* Support Ticket Form */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
          Submit a Support Ticket
        </h2>

        <form onSubmit={handleSubmitTicket} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Category
            </label>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="general">General Inquiry</option>
              <option value="technical">Technical Issue</option>
              <option value="billing">Billing Question</option>
              <option value="account">Account Management</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Subject
            </label>
            <input
              type="text"
              value={ticketSubject}
              onChange={(e) => setTicketSubject(e.target.value)}
              className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Message
            </label>
            <textarea
              value={ticketMessage}
              onChange={(e) => setTicketMessage(e.target.value)}
              rows={4}
              className="w-full rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white focus:border-blue-500 focus:ring-blue-500"
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Submit Ticket
          </button>
        </form>
      </div>
    </motion.div>
  );
};

export default Support;
