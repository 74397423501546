import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../../config/firebase';
import PageTitle from '../../components/PageTitle';

interface Video {
  title: string;
  description: string;
  thumbnailUrl: string;
  youtubeUrl: string;
}

interface VideoGallery {
  id: string;
  title: string;
  description: string;
  videos: Video[];
  createdAt: any;
  updatedAt: any;
}

const VideoGalleryPage = () => {
  const [galleries, setGalleries] = useState<VideoGallery[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

  useEffect(() => {
    const q = query(
      collection(db, 'video-gallery'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const galleriesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as VideoGallery[];

      setGalleries(galleriesData);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching video galleries:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const VideoModal = ({ video, onClose }: { video: Video; onClose: () => void }) => {
    // Extract video ID from YouTube URL
    const getYoutubeVideoId = (url: string) => {
      const match = url.match(/[?&]v=([^&]+)/);
      return match ? match[1] : '';
    };

    const videoId = getYoutubeVideoId(video.youtubeUrl);
    const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
        <div className="w-full max-w-5xl mx-auto bg-gray-800 rounded-lg overflow-hidden">
          <div className="relative pt-[56.25%]"> {/* 16:9 aspect ratio */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-white hover:text-gray-300 z-10 bg-black bg-opacity-50 rounded-full p-2"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <iframe
              src={embedUrl}
              title={video.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full"
            />
          </div>
          <div className="p-4">
            <h3 className="text-xl font-semibold text-white mb-2">{video.title}</h3>
            {video.description && (
              <p className="text-gray-300">{video.description}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-900 min-h-screen">
      <PageTitle
        title="Video Gallery"
        subtitle="Watch our latest videos and stay updated with our activities"
      />

      {/* Video Galleries */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {loading ? (
          <div className="flex items-center justify-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : galleries.length === 0 ? (
          <div className="text-center text-white text-lg py-12">No videos available</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {galleries.map((gallery) => (
              gallery.videos && gallery.videos.map((video, index) => (
                <div 
                  key={`${gallery.id}-${index}`}
                  className="group relative bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 rounded-2xl p-1 hover:from-blue-600 hover:to-purple-600 transition-all duration-300"
                >
                  <div className="bg-gray-900 rounded-xl overflow-hidden">
                    <div
                      className="relative aspect-video cursor-pointer overflow-hidden"
                      onClick={() => setSelectedVideo(video)}
                    >
                      <img
                        src={video.thumbnailUrl}
                        alt={video.title}
                        className="w-full h-full object-cover transform transition-all duration-500 group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300">
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="w-20 h-20 bg-white/90 backdrop-blur-sm rounded-full flex items-center justify-center transform scale-0 group-hover:scale-100 transition-transform duration-500 shadow-2xl">
                            <svg className="w-10 h-10 text-black translate-x-1" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M6.3 2.841A1.5 1.5 0 004 4.11v11.78a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-6 space-y-4">
                      <div>
                        <h3 className="text-xl font-bold text-white mb-2 line-clamp-1 group-hover:text-blue-400 transition-colors duration-300">
                          {video.title}
                        </h3>
                        {video.description && (
                          <p className="text-gray-400 text-sm line-clamp-2 group-hover:text-gray-300 transition-colors duration-300">
                            {video.description}
                          </p>
                        )}
                      </div>
                      <div className="pt-4 border-t border-gray-800/50">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                              {gallery.title}
                            </h4>
                            {gallery.description && (
                              <p className="text-gray-500 text-sm mt-1 line-clamp-1">
                                {gallery.description}
                              </p>
                            )}
                          </div>
                          <div className="flex items-center space-x-2 text-sm text-blue-400/80">
                            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                            </svg>
                            <span className="whitespace-nowrap">
                              {new Date(gallery.createdAt.seconds * 1000).toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                              })}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ))}
          </div>
        )}
      </div>

      {/* Video Modal */}
      {selectedVideo && (
        <VideoModal video={selectedVideo} onClose={() => setSelectedVideo(null)} />
      )}
    </div>
  );
};

export default VideoGalleryPage;
