import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import logo from "../../assets/logo.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const quickLinks = [
    { name: "About Us", path: "/about" },
    { name: "National", path: "/national" },
    { name: "State", path: "/state" },
    { name: "District", path: "/district" },
    { name: "Events", path: "/events" },
  ];

  const resources = [
    { name: "Photo Gallery", path: "/photo" },
    { name: "Video Gallery", path: "/video" },
    { name: "News & Updates", path: "/news" },
    { name: "Research Papers", path: "/research" },
    { name: "Publications", path: "/publications" },
  ];

  const socialLinks = [
    { icon: <FaFacebookF />, path: "https://facebook.com" },
    { icon: <FaTwitter />, path: "https://twitter.com" },
    { icon: <FaInstagram />, path: "https://instagram.com" },
    { icon: <FaLinkedinIn />, path: "https://linkedin.com" },
    { icon: <FaYoutube />, path: "https://youtube.com" },
  ];

  useEffect(() => {
    if (localStorage.getItem("darkMode") !== "false") {
      document.documentElement.classList.add("dark");
    }
  }, []);

  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto px-4 py-12">
        <div className="rounded-xl bg-gray-800/50 backdrop-blur-sm p-8 shadow-2xl">
          {/* Top Section with Logo and Description */}
          <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-4 mb-12">
            {/* Organization Info */}
            <div className="space-y-6">
              <Link to="/" className="inline-block">
                <img 
                  src={logo} 
                  alt="Logo" 
                  className="h-16 w-auto object-contain"
                />
              </Link>
              <p className="text-sm leading-relaxed text-gray-400">
                Crime Control & Research Organization is dedicated to creating a safer society
                through research, education, and community engagement.
              </p>
              {/* Contact Info */}
              <div className="space-y-3">
                <a href="tel:+917409832549" className="flex items-center space-x-3 text-sm hover:text-white transition-colors">
                  <FaPhoneAlt className="text-blue-400" />
                  <span>+91 7409832549</span>
                </a>
                <a href="mailto:info@ccroindia.in" className="flex items-center space-x-3 text-sm hover:text-white transition-colors">
                  <FaEnvelope className="text-blue-400" />
                  <span>info@ccroindia.in</span>
                </a>
                <div className="flex items-center space-x-3 text-sm">
                  <FaMapMarkerAlt className="text-blue-400 flex-shrink-0" />
                  <span>Dehradun, Uttarakhand - 249407</span>
                </div>
              </div>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-lg font-semibold mb-6 text-white">Quick Links</h3>
              <ul className="space-y-3">
                {quickLinks.map((link) => (
                  <li key={link.path}>
                    <Link
                      to={link.path}
                      className="text-sm hover:text-blue-400 transition-colors flex items-center space-x-2"
                    >
                      <span className="w-1.5 h-1.5 bg-blue-400 rounded-full"></span>
                      <span>{link.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Resources */}
            <div>
              <h3 className="text-lg font-semibold mb-6 text-white">Resources</h3>
              <ul className="space-y-3">
                {resources.map((link) => (
                  <li key={link.path}>
                    <Link
                      to={link.path}
                      className="text-sm hover:text-blue-400 transition-colors flex items-center space-x-2"
                    >
                      <span className="w-1.5 h-1.5 bg-blue-400 rounded-full"></span>
                      <span>{link.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Newsletter */}
            <div>
              <h3 className="text-lg font-semibold mb-6 text-white">Newsletter</h3>
              <p className="text-sm text-gray-400 mb-4">
                Subscribe to our newsletter for the latest updates and news.
              </p>
              <form className="space-y-3">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 rounded-lg bg-gray-700/50 border border-gray-600 focus:outline-none focus:border-blue-400 text-sm"
                />
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-blue-500 hover:bg-blue-600 transition-colors rounded-lg text-white text-sm font-medium"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>

          {/* Divider */}
          <div className="border-t border-gray-700/50 my-8"></div>

          {/* Bottom Section */}
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            {/* Copyright */}
            <p className="text-sm text-gray-400">
              {currentYear} Crime Control & Research Organization. All rights reserved.
            </p>

            {/* Social Links */}
            <div className="flex space-x-4">
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-8 h-8 rounded-lg bg-gray-700/50 flex items-center justify-center hover:bg-blue-500 transition-colors text-gray-400 hover:text-white"
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
