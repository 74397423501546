import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import { FaDownload, FaFileAlt, FaTimes, FaEye } from 'react-icons/fa';
import { Document, Page } from 'react-pdf';
import affidavitPdf from '../../assets/affidavit.pdf';
import { downloadFile } from '../../utils/downloadHelper';
import { usePdfWorker } from '../../hooks/usePdfWorker';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

interface DocumentItem {
  name: string;
  description: string;
  fileName: string;
  fileUrl: string;
  category: string;
}

const documents: DocumentItem[] = [
  {
    name: 'Affidavit Form',
    description: 'Official affidavit form for CCRO membership',
    fileName: 'affidavit.pdf',
    fileUrl: affidavitPdf,
    category: 'Official Forms'
  }
];

const Download: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Initialize PDF worker
  usePdfWorker();

  const handlePreview = (fileUrl: string) => {
    setIsLoading(true);
    setError(null);
    setPreviewUrl(fileUrl);
  };

  const closePreview = () => {
    setPreviewUrl(null);
    setNumPages(null);
    setError(null);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const onDocumentLoadError = (error: Error) => {
    console.error('Error loading PDF:', error);
    setError('Failed to load PDF. Please try downloading instead.');
    setIsLoading(false);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen py-20 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl font-bold mb-8 dark:text-white">
              Downloads
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-300 mb-12">
              Access and download our official forms and documents
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {documents.map((doc, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300"
              >
                <div className="flex items-center space-x-3 mb-4">
                  <FaFileAlt className="text-blue-600 dark:text-blue-400 text-xl" />
                  <h3 className="text-xl font-semibold dark:text-white">
                    {doc.name}
                  </h3>
                </div>
                <p className="text-gray-600 dark:text-gray-300 mb-6">
                  {doc.description}
                </p>
                <div className="flex space-x-4">
                  <button
                    onClick={() => handlePreview(doc.fileUrl)}
                    className="flex items-center space-x-2 px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                  >
                    <FaEye />
                    <span>Preview</span>
                  </button>
                  <button
                    onClick={() => downloadFile(doc.fileUrl, doc.fileName)}
                    className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                  >
                    <FaDownload />
                    <span>Download</span>
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* PDF Preview Modal */}
      <AnimatePresence>
        {previewUrl && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4"
            onClick={closePreview}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white dark:bg-gray-800 rounded-lg p-4 max-w-3xl w-full max-h-[90vh] overflow-auto"
              onClick={e => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold dark:text-white">Document Preview</h3>
                <button
                  onClick={closePreview}
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <FaTimes className="text-xl" />
                </button>
              </div>
              <div className="relative bg-gray-100 dark:bg-gray-700 rounded-lg overflow-auto">
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100/80 dark:bg-gray-700/80">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                  </div>
                )}
                {error ? (
                  <div className="p-4 text-center text-red-500 dark:text-red-400">
                    {error}
                  </div>
                ) : (
                  <Document
                    file={previewUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                    className="mx-auto"
                    loading={
                      <div className="flex justify-center p-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                      </div>
                    }
                  >
                    {Array.from(new Array(numPages || 0), (_, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderTextLayer={true}
                        renderAnnotationLayer={true}
                        className="mb-4"
                        loading={
                          <div className="flex justify-center p-4">
                            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                          </div>
                        }
                      />
                    ))}
                  </Document>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Download;
