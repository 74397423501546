import React from 'react';

interface PageTitleProps {
  title: string;
  subtitle: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, subtitle }) => {
  return (
    <div className="relative overflow-hidden -mt-[70px] h-[300px] flex items-center">
      {/* Animated background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-900/20 via-gray-900/0 to-gray-900/0" />
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:40px_40px]" />
      </div>
      
      {/* Decorative circles */}
      <div className="absolute top-1/4 left-1/4 w-48 h-48 bg-blue-500/10 rounded-full blur-3xl animate-pulse" />
      <div className="absolute top-1/3 right-1/3 w-72 h-72 bg-indigo-500/10 rounded-full blur-3xl animate-pulse animation-delay-2000" />
      
      <div className="relative w-full">
        <div className="mx-auto max-w-2xl text-center relative px-6 sm:px-8 lg:px-12">
          {/* Glowing line */}
          <div className="absolute left-1/2 -translate-x-1/2 -top-8 w-40 h-px bg-gradient-to-r from-transparent via-blue-500 to-transparent animate-pulse" />
          
          {/* Badge */}
          <div className="inline-flex items-center justify-center mb-4">
            <div className="relative inline-flex items-center space-x-2 px-4 py-1.5 rounded-full bg-blue-500/10 border border-blue-500/20 backdrop-blur-sm">
              <span className="relative flex h-2 w-2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75" />
                <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500" />
              </span>
              <span className="text-sm font-medium text-blue-300">{title}</span>
            </div>
          </div>
          
          {/* Title with gradient and animation */}
          <h1 className="relative text-3xl font-bold tracking-tight text-white sm:text-4xl mb-4 animate-fade-in-up">
            <span className="inline-block bg-gradient-to-r from-blue-200 via-blue-100 to-white bg-clip-text text-transparent">
              {title}
            </span>
          </h1>
          
          {/* Subtitle */}
          <p className="text-base sm:text-lg text-gray-300 max-w-xl mx-auto animate-fade-in-up animation-delay-150">
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
