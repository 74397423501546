import React from 'react';
import { motion } from 'framer-motion';

interface MemberDetails {
  photo: string;
  fullName: string;
  permanentEnrollmentNumber: string;
  memberType: string;
  designation: string;
  phone: string;
  languagesKnown: string;
  memberStatus: string;
  currentState: string;
  currentDistrict: string;
  currentCity: string;
  currentPinCode: string;
  dateOfBirth: string;
  bloodGroup: string;
}

interface SearchCardProps {
  member: MemberDetails;
}

const SearchCard: React.FC<SearchCardProps> = ({ member }) => {
  const getBadges = () => {
    return [
      <span key="memberType" className="px-3 py-1 bg-blue-500/10 text-blue-400 rounded-full text-sm font-medium">
        {member.memberType}
      </span>,
      <span key="designation" className="px-3 py-1 bg-purple-500/10 text-purple-400 rounded-full text-sm font-medium">
        {member.designation}
      </span>,
      <span key="status" className="px-3 py-1 bg-green-500/10 text-green-400 rounded-full text-sm font-medium">
        {member.memberStatus}
      </span>
    ];
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="w-full bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl shadow-xl overflow-hidden"
    >
      <div className="p-6 sm:p-8">
        <div className="flex flex-col sm:flex-row gap-6 h-full">
          {/* Profile Image Section */}
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2 }}
            className="flex-shrink-0 mx-auto sm:mx-0 min-w-[250px]"
          >
            <div className="relative w-[250px] h-[300px] rounded-xl overflow-hidden ring-2 ring-blue-500/30">
              <img
                src={member.photo || '/default-avatar.png'}
                alt={member.fullName}
                className="absolute inset-0 w-full h-full object-cover object-center"
              />
              <div className="absolute inset-0 ring-1 ring-inset ring-white/10" />
            </div>
          </motion.div>

          {/* Member Details Section */}
          <div className="flex-1">
            {/* Primary Info */}
            <div className="mb-6">
              <motion.h3
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-2xl font-bold text-white mb-4"
              >
                {member.fullName}
              </motion.h3>
              
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="flex flex-wrap gap-2"
              >
                {getBadges()}
              </motion.div>
            </div>

            {/* Detailed Info Grid */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-sm"
            >
              <div>
                <div className="text-gray-400">Enrollment Number</div>
                <div className="text-white font-medium">{member.permanentEnrollmentNumber}</div>
              </div>
              <div>
                <div className="text-gray-400">Languages Known</div>
                <div className="text-white font-medium">{member.languagesKnown}</div>
              </div>
              <div>
                <div className="text-gray-400">Date of Birth</div>
                <div className="text-white font-medium">{member.dateOfBirth}</div>
              </div>
              <div>
                <div className="text-gray-400">Current City</div>
                <div className="text-white font-medium">{member.currentCity}</div>
              </div>
              <div>
                <div className="text-gray-400">Current District</div>
                <div className="text-white font-medium">{member.currentDistrict}</div>
              </div>
              <div>
                <div className="text-gray-400">Current State</div>
                <div className="text-white font-medium">{member.currentState}</div>
              </div>
              <div>
                <div className="text-gray-400">Pin Code</div>
                <div className="text-white font-medium">{member.currentPinCode}</div>
              </div>
              <div>
                <div className="text-gray-400">Blood Group</div>
                <div className="text-white font-medium">{member.bloodGroup}</div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SearchCard;
