import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../config/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, sendEmailVerification, reload } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { FaEnvelope, FaSpinner } from 'react-icons/fa';

const COOLDOWN_DURATION = 300; // 5 minutes in seconds

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(COOLDOWN_DURATION);

  // Check if there's a saved cooldown timestamp
  useEffect(() => {
    const checkSavedCooldown = async () => {
      if (!auth.currentUser) return;

      try {
        const userDoc = await getDoc(doc(db, 'userDetails', auth.currentUser.uid));
        const userData = userDoc.data();
        
        if (userData?.lastEmailVerificationSent) {
          const lastSent = new Date(userData.lastEmailVerificationSent).getTime();
          const now = new Date().getTime();
          const diffInSeconds = Math.floor((now - lastSent) / 1000);
          
          if (diffInSeconds < COOLDOWN_DURATION) {
            setResendDisabled(true);
            setCountdown(COOLDOWN_DURATION - diffInSeconds);
          }
        }
      } catch (error) {
        console.error('Error checking cooldown:', error);
      }
    };

    checkSavedCooldown();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        await reload(user);
        
        if (user.emailVerified) {
          const userDoc = await getDoc(doc(db, 'userDetails', user.uid));
          const userData = userDoc.data();
          
          await updateDoc(doc(db, 'userDetails', user.uid), {
            emailVerified: true,
            status: userData?.status === 'pending' ? 'active' : userData?.status,
            updatedAt: new Date().toISOString()
          });
          
          toast.success('Email verified successfully! You can now log in.');
          navigate('/login');
          return;
        }
      } catch (error) {
        console.error('Error checking verification status:', error);
      }
      
      setIsLoading(false);
    });

    // Check verification status periodically
    const checkInterval = setInterval(async () => {
      if (auth.currentUser) {
        try {
          await reload(auth.currentUser);
          if (auth.currentUser.emailVerified) {
            const userDoc = await getDoc(doc(db, 'userDetails', auth.currentUser.uid));
            const userData = userDoc.data();
            
            await updateDoc(doc(db, 'userDetails', auth.currentUser.uid), {
              emailVerified: true,
              status: userData?.status === 'pending' ? 'active' : userData?.status,
              updatedAt: new Date().toISOString()
            });
            
            toast.success('Email verified successfully! You can now log in.');
            navigate('/login');
          }
        } catch (error) {
          console.error('Error checking verification status:', error);
        }
      }
    }, 5000);

    return () => {
      unsubscribe();
      clearInterval(checkInterval);
    };
  }, [navigate]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          const newCount = prev - 1;
          if (newCount <= 0) {
            setResendDisabled(false);
          }
          return newCount;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendDisabled, countdown]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleResendEmail = async () => {
    if (!auth.currentUser) {
      toast.error('No user found. Please try logging in again.');
      navigate('/login');
      return;
    }

    if (resendDisabled) {
      toast.error(`Please wait ${formatTime(countdown)} before requesting another email.`);
      return;
    }

    try {
      const userDoc = await getDoc(doc(db, 'userDetails', auth.currentUser.uid));
      const userData = userDoc.data();
      
      if (userData?.lastEmailVerificationSent) {
        const lastSent = new Date(userData.lastEmailVerificationSent).getTime();
        const now = new Date().getTime();
        const diffInSeconds = Math.floor((now - lastSent) / 1000);
        
        if (diffInSeconds < COOLDOWN_DURATION) {
          const remainingTime = COOLDOWN_DURATION - diffInSeconds;
          setResendDisabled(true);
          setCountdown(remainingTime);
          toast.error(`Please wait ${formatTime(remainingTime)} before requesting another email.`);
          return;
        }
      }

      const actionCodeSettings = {
        url: window.location.origin + '/verify-email-success',
        handleCodeInApp: true,
      };
      
      await sendEmailVerification(auth.currentUser, actionCodeSettings);
      
      // Update last email sent timestamp in Firestore
      await updateDoc(doc(db, 'userDetails', auth.currentUser.uid), {
        lastEmailVerificationSent: new Date().toISOString()
      });
      
      toast.success('Verification email sent! Please check your inbox and spam folder.');
      setResendDisabled(true);
      setCountdown(COOLDOWN_DURATION);
    } catch (error: any) {
      console.error('Error sending verification email:', error);
      if (error.code === 'auth/too-many-requests') {
        setResendDisabled(true);
        setCountdown(COOLDOWN_DURATION);
        toast.error('Too many attempts. Please try again in 5 minutes.');
      } else {
        toast.error('Failed to send verification email. Please try again later.');
      }
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <FaSpinner className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center p-0 animated-bg">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full">
          <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-gradient-to-br from-blue-500/10 to-purple-500/10 animate-rotate-slow" />
          <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-gradient-to-tl from-indigo-500/10 to-pink-500/10 animate-rotate-slow-reverse" />
        </div>
      </div>

      {/* Verification Content */}
      <div className="max-w-xl w-full relative z-10 mt-40 m-auto px-4">
        <div className="floating-card glass-effect rounded-2xl p-8 shadow-2xl backdrop-blur-xl">
          <div className="text-center space-y-6">
            <div className="relative inline-block">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 to-purple-500/30 rounded-full blur-2xl opacity-75 animate-pulse" />
              <div className="relative p-4">
                <FaEnvelope className="h-16 w-16 text-blue-500 mx-auto" />
              </div>
            </div>

            <div className="space-y-4">
              <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Verify Your Email
              </h2>
              <p className="text-gray-600">
                We've sent you a verification email. Please check your inbox and spam folder.
                {resendDisabled && (
                  <div className="mt-2 text-sm text-gray-500">
                    You can request another email in {formatTime(countdown)}
                  </div>
                )}
              </p>
              <div className="space-y-4">
                <button
                  onClick={handleResendEmail}
                  disabled={resendDisabled}
                  className={`w-full py-3 px-4 rounded-xl text-white font-semibold transition-all duration-200 ${
                    resendDisabled
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600'
                  }`}
                >
                  {resendDisabled
                    ? `Resend in ${formatTime(countdown)}`
                    : 'Resend Verification Email'}
                </button>
                <button
                  onClick={() => navigate('/login')}
                  className="w-full py-3 px-4 rounded-xl border-2 border-blue-500 text-blue-500 font-semibold hover:bg-blue-50 transition-all duration-200"
                >
                  Back to Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
