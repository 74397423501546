import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { QRCodeSVG } from 'qrcode.react';
import { auth } from '../../../config/firebase';
import { toast } from 'react-hot-toast';
import { FaDownload, FaShareAlt } from 'react-icons/fa';

const QRCodePage = () => {
  const [qrValue, setQrValue] = useState('');
  const [qrSize, setQrSize] = useState(256);
  const [bgColor, setBgColor] = useState('#FFFFFF');
  const [fgColor, setFgColor] = useState('#000000');
  const [includeMargin, setIncludeMargin] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Generate a unique QR code value for the user
        setQrValue(`https://your-domain.com/verify/${user.uid}`);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const downloadQRCode = () => {
    const svg = document.querySelector('svg');
    if (svg) {
      // Convert SVG to canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const svgData = new XMLSerializer().serializeToString(svg);
      const img = new Image();
      
      img.onload = () => {
        canvas.width = qrSize;
        canvas.height = qrSize;
        ctx?.drawImage(img, 0, 0);
        
        // Download the canvas as PNG
        const pngUrl = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'qrcode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toast.success('QR Code downloaded successfully!');
      };

      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    }
  };

  const shareQRCode = async () => {
    try {
      const svg = document.querySelector('svg');
      if (svg) {
        // Convert SVG to canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const svgData = new XMLSerializer().serializeToString(svg);
        const img = new Image();
        
        img.onload = async () => {
          canvas.width = qrSize;
          canvas.height = qrSize;
          ctx?.drawImage(img, 0, 0);
          
          // Convert canvas to blob
          const blob = await new Promise<Blob>((resolve) => 
            canvas.toBlob((b) => resolve(b as Blob), 'image/png')
          );
          
          const file = new File([blob], 'qrcode.png', { type: 'image/png' });
          const shareData = {
            title: 'My QR Code',
            text: 'Scan this QR code to verify my membership',
            files: [file],
          };

          if (navigator.share && navigator.canShare(shareData)) {
            await navigator.share(shareData);
            toast.success('QR Code shared successfully!');
          } else {
            toast.error('Sharing is not supported on this device');
          }
        };

        img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
      }
    } catch (error) {
      console.error('Error sharing QR code:', error);
      toast.error('Failed to share QR code');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-4xl mx-auto"
    >
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
          Your Membership QR Code
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* QR Code Display */}
          <div className="flex flex-col items-center space-y-6">
            <div className="bg-white p-4 rounded-lg shadow-inner">
              <QRCodeSVG
                value={qrValue}
                size={qrSize}
                bgColor={bgColor}
                fgColor={fgColor}
                includeMargin={includeMargin}
                level="H"
              />
            </div>

            <div className="flex space-x-4">
              <button
                onClick={downloadQRCode}
                className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <FaDownload />
                <span>Download</span>
              </button>
              <button
                onClick={shareQRCode}
                className="flex items-center space-x-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
              >
                <FaShareAlt />
                <span>Share</span>
              </button>
            </div>
          </div>

          {/* QR Code Customization */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Size
              </label>
              <input
                type="range"
                min="128"
                max="512"
                step="32"
                value={qrSize}
                onChange={(e) => setQrSize(Number(e.target.value))}
                className="w-full"
              />
              <div className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                {qrSize}px
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Background Color
              </label>
              <input
                type="color"
                value={bgColor}
                onChange={(e) => setBgColor(e.target.value)}
                className="w-full h-10 rounded-lg cursor-pointer"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Foreground Color
              </label>
              <input
                type="color"
                value={fgColor}
                onChange={(e) => setFgColor(e.target.value)}
                className="w-full h-10 rounded-lg cursor-pointer"
              />
            </div>

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="includeMargin"
                checked={includeMargin}
                onChange={(e) => setIncludeMargin(e.target.checked)}
                className="rounded text-blue-600 focus:ring-blue-500"
              />
              <label
                htmlFor="includeMargin"
                className="text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Include Margin
              </label>
            </div>
          </div>
        </div>

        <div className="mt-8 p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg">
          <h2 className="text-lg font-semibold text-blue-800 dark:text-blue-200 mb-2">
            How to use your QR Code
          </h2>
          <ul className="list-disc list-inside space-y-2 text-blue-700 dark:text-blue-300">
            <li>Download or share your unique QR code</li>
            <li>Present this QR code when requested for membership verification</li>
            <li>The QR code contains your unique member identification</li>
            <li>Keep your QR code secure and don't share it with unauthorized individuals</li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default QRCodePage;
