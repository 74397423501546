import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase';
import PageTitle from '../../components/PageTitle';

interface Gallery {
  id: string;
  title: string;
  category: string;
  photos: Array<{
    url: string;
    title: string;
    fileName: string;
    description: string;
  }>;
  isPublished: boolean;
  status: string;
}

interface PhotoPopupProps {
  photo: Gallery['photos'][0];
  galleryTitle: string;
  onClose: () => void;
}

const PhotoPopup: React.FC<PhotoPopupProps> = ({ photo, galleryTitle, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="max-w-4xl w-full bg-gray-800 rounded-lg overflow-hidden relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-gray-300 z-10"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="relative aspect-w-16 aspect-h-9">
          <img
            src={photo.url}
            alt={photo.title || photo.fileName}
            className="w-full h-full object-contain"
          />
        </div>
        <div className="p-4 bg-gray-800">
          <h3 className="text-xl font-semibold text-white mb-2">{galleryTitle}</h3>
          {photo.description && (
            <p className="text-gray-300">{photo.description}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const PhotoGallery = () => {
  const [galleries, setGalleries] = useState<Gallery[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedPhoto, setSelectedPhoto] = useState<{photo: Gallery['photos'][0], galleryTitle: string} | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(
      collection(db, 'photo-gallery'),
      where('isPublished', '==', true),
      where('status', '==', 'active')
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const galleriesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Gallery));

        const uniqueCategories = Array.from(
          new Set(galleriesData.map(gallery => gallery.category))
        )
          .filter(Boolean)
          .sort();

        setGalleries(galleriesData);
        setCategories(uniqueCategories);
        
        // Set first category as default
        if (uniqueCategories.length > 0 && !selectedCategory) {
          setSelectedCategory(uniqueCategories[0]);
        }
        
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching galleries:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [selectedCategory]);

  const filteredGalleries = galleries.filter(gallery => gallery.category === selectedCategory);

  return (
    <div className="bg-gray-900 min-h-screen">
      <PageTitle
        title="Photo Gallery"
        subtitle="Explore our collection of memorable moments and impactful events"
      />

      {/* Category Navigation */}
      <div className="bg-gray-800 sticky top-0 z-10 shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex space-x-1 overflow-x-auto py-3 scrollbar-hide">
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 whitespace-nowrap rounded-full transition-colors ${
                  selectedCategory === category
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-300 hover:text-white hover:bg-gray-700'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Gallery Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {loading ? (
          <div className="text-center text-white">Loading galleries...</div>
        ) : filteredGalleries.length === 0 ? (
          <div className="text-center text-white">No galleries found in this category</div>
        ) : (
          <div className="space-y-12">
            {filteredGalleries.map(gallery => (
              <div key={gallery.id} className="space-y-4">
                <h2 className="text-2xl font-bold text-white">{gallery.title}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {gallery.photos && gallery.photos.map((photo, index) => (
                    <div
                      key={`${gallery.id}-${index}`}
                      className="relative group overflow-hidden rounded-lg shadow-lg aspect-w-16 aspect-h-9 cursor-pointer"
                      onClick={() => setSelectedPhoto({ photo, galleryTitle: gallery.title })}
                    >
                      <img
                        src={photo.url}
                        alt={photo.title || photo.fileName}
                        className="w-full h-64 object-cover transform transition-transform duration-300 group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-opacity duration-300">
                        <div className="absolute bottom-0 left-0 right-0 p-4 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          {photo.description && (
                            <p className="text-sm">{photo.description}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Photo Popup */}
      {selectedPhoto && (
        <PhotoPopup
          photo={selectedPhoto.photo}
          galleryTitle={selectedPhoto.galleryTitle}
          onClose={() => setSelectedPhoto(null)}
        />
      )}
    </div>
  );
};

export default PhotoGallery;
