import React, { useState, useEffect } from 'react';
import { FaCalendar, FaMapMarkerAlt, FaClock, FaUsers } from 'react-icons/fa';
import PageTitle from '../../components/PageTitle';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface Event {
  id: string;
  title: string;
  capacity: number;
  content: string;
  createdAt: any;
  createdBy: string;
  description: string;
  endDate: any;
  featuredImage: {
    alt: string;
    url: string;
  };
}

const Events = () => {
  const [selectedCategory, setSelectedCategory] = useState('upcoming');
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);

  const categories = [
    { id: 'upcoming', name: 'Upcoming Events' },
    { id: 'current', name: 'Current Events' },
    { id: 'past', name: 'Past Events' }
  ];

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsData = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Event[];
        setEvents(eventsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching events:', error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const getCurrentDate = () => new Date();

  const filteredEvents = (() => {
    const now = getCurrentDate();
    
    switch (selectedCategory) {
      case 'upcoming':
        return events.filter(event => new Date(event.endDate.toDate()) > now);
      case 'current':
        return events.filter(event => {
          const eventEnd = new Date(event.endDate.toDate());
          return eventEnd >= now && eventEnd <= new Date(now.getTime() + 24 * 60 * 60 * 1000); // Within next 24 hours
        });
      case 'past':
        return events.filter(event => new Date(event.endDate.toDate()) < now);
      default:
        return events.filter(event => new Date(event.endDate.toDate()) > now);
    }
  })();

  const formatDate = (date: any) => {
    try {
      return date.toDate().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      return 'Date not available';
    }
  };

  return (
    <div className="bg-gray-900 min-h-screen">
      <PageTitle
        title="Events & Programs"
        subtitle="Stay updated with our latest events, workshops, and community programs"
      />
      
      {/* Hero Section */}
      <div className="relative bg-gradient-to-b from-indigo-100/20 dark:from-indigo-500/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
          </div>
        </div>
      </div>

      {/* Category Filter */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-8">
        <div className="flex flex-wrap gap-4 justify-center">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-4 py-2 rounded-full text-sm font-medium ${
                selectedCategory === category.id
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-800 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700'
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>

      {/* Events Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {loading ? (
          <div className="text-center text-white">Loading events...</div>
        ) : filteredEvents.length === 0 ? (
          <div className="text-center text-white">No events found</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredEvents.map((event) => (
              <div
                key={event.id}
                className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                {event.featuredImage && (
                  <img
                    src={event.featuredImage.url}
                    alt={event.featuredImage.alt || 'Event featured image'}
                    className="w-full h-48 object-cover"
                  />
                )}
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                    {event.title}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">
                    {event.description}
                  </p>
                  <div className="flex items-center text-gray-500 dark:text-gray-400 mb-2">
                    <FaCalendar className="mr-2" />
                    <span>{formatDate(event.endDate)}</span>
                  </div>
                  <div className="flex items-center text-gray-500 dark:text-gray-400 mb-2">
                    <FaUsers className="mr-2" />
                    <span>Capacity: {event.capacity}</span>
                  </div>
                  <div className="mt-4">
                    <div 
                      className="prose prose-sm dark:prose-invert max-w-none"
                      dangerouslySetInnerHTML={{ __html: event.content }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Events;
