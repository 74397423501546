import React from 'react';
import { motion } from 'framer-motion';

export interface MemberCardProps {
  name: string;
  photoUrl: string;
  rank: string;
  memberType: string;
  state?: string;
  officeLocation?: string;
  designation?: string;
  children?: React.ReactNode;
}

const MemberCard: React.FC<MemberCardProps> = ({ 
  name, 
  photoUrl, 
  rank,
  memberType, 
  state,
  officeLocation,
  designation,
  children
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="relative group"
    >
      <div className="relative w-full" style={{ paddingBottom: '130%' }}> 
        <div className="absolute inset-0 rounded-lg overflow-hidden bg-gray-800 shadow-lg">
          <div className="absolute inset-0 bg-black bg-opacity-30 group-hover:bg-opacity-0 transition-all duration-300 z-10"></div>
          <img
            loading="lazy"
            src={photoUrl || '/placeholder.png'}
            alt={name}
            className="w-full h-full object-cover"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = '/default-avatar.png';
            }}
          />
          <div className="absolute bottom-3 sm:bottom-4 left-3 sm:left-4 right-3 sm:right-4 bg-black/60 backdrop-blur-sm rounded-lg p-3 sm:p-4 transform transition-all duration-300 group-hover:translate-y-0 group-hover:opacity-100 z-20">
            {children ? children : (
              <>
                <h3 className="text-white font-semibold text-base sm:text-lg mb-2 text-center line-clamp-1">{name}</h3>
                <div className="flex flex-col items-center space-y-1 text-xs sm:text-sm text-gray-300">
                  <span className="line-clamp-1">{memberType} {designation}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default MemberCard;
