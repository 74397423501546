import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
// cspell:disable-next-line
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase';
import MemberCard from '../../components/MemberCard';
import PageTitle from '../../components/PageTitle';

interface Member {
  id: string;
  fullName: string;
  photo: string;
  memberType: string;
  rank: string;
  memberStatus: string;
  designation: string;
}

const designationOrder = [
  "President",
  "ADL President",
  "Senior Vice President",
  "Vice President",
  "Secretary",
  "General Secretary",
  "Joint Secretary",
  "Senior Advisor",
  "Advisor",
  "IT Incharge",
  "Special Officer",
  "Media Co-ordinator",
  "Legal Advisor",
  "Lifetime Member"
];

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const National = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const membersQuery = query(
          collection(db, 'userDetails'),
          where('memberType', '==', 'National'),
          where('memberStatus', '==', 'Active')
        );
        const membersSnapshot = await getDocs(membersQuery);
        
        const membersData = membersSnapshot.docs
          .map(doc => ({
            id: doc.id,
            fullName: doc.data().fullName || 'Unknown',
            photo: doc.data().photo || '/default-avatar.png',
            memberType: doc.data().memberType || 'Member',
            rank: doc.data().rank || 'Member',
            memberStatus: doc.data().memberStatus || 'Active',
            designation: doc.data().designation || 'Member'
          }))
          .sort((a, b) => {
            // First sort by designation order
            const aIndex = designationOrder.indexOf(a.designation);
            const bIndex = designationOrder.indexOf(b.designation);
            if (aIndex !== bIndex) {
              return aIndex - bIndex;
            }
            // If same designation, sort by name
            return a.fullName.localeCompare(b.fullName);
          });

        setMembers(membersData);
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, []);

  return (
    <div className="bg-gray-900 min-h-screen">
      <PageTitle
        title="National Members"
        subtitle="Meet our dedicated national team working towards a safer India"
      />
      
      <div className="container mx-auto px-4 py-8 sm:py-12">
        {loading ? (
          <div className="flex justify-center items-center min-h-[300px] sm:min-h-[400px]">
            <div className="animate-spin rounded-full h-10 w-10 sm:h-12 sm:w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : members.length === 0 ? (
          <div className="text-center text-gray-400">
            No members found
          </div>
        ) : (
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 max-w-7xl mx-auto"
          >
            {members.map((member) => (
              <MemberCard
                key={member.id}
                name={member.fullName}
                photoUrl={member.photo}
                rank={member.rank}
                designation={member.designation}
                memberType={member.memberType}
              />
            ))}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default National;
