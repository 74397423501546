import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation, Outlet } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaNewspaper, 
  FaPlusCircle, 
  FaEdit, 
  FaQrcode, 
  FaDownload, 
  FaBell, 
  FaHeadset, 
  FaSignOutAlt,
  FaBars,
  FaTimes,
  FaHome,
  FaUserCog
} from 'react-icons/fa';
import { auth, db } from '../../../config/firebase';
import { signOut } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { getDoc, doc } from 'firebase/firestore';

const menuItems = [
  { name: 'Dashboard', href: '/dashboard', icon: FaHome },
  { name: 'Articles', href: '/dashboard/articles', icon: FaNewspaper },
  { name: 'New Article', href: '/dashboard/new-article', icon: FaPlusCircle },
  { name: 'Edit Article', href: '/dashboard/edit-article', icon: FaEdit },
  { name: 'QR Code', href: '/dashboard/qr-code', icon: FaQrcode },
  { name: 'Download', href: '/dashboard/download', icon: FaDownload },
  { name: 'Notifications', href: '/dashboard/notifications', icon: FaBell },
  { name: 'Support', href: '/dashboard/support', icon: FaHeadset }
];

const DashboardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [currentMenuItems, setCurrentMenuItems] = useState(menuItems);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 1024;
      setIsMobile(mobile);
      if (!mobile) setIsSidebarOpen(true);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const checkUserStatus = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log('No authenticated user found');
        navigate('/login');
        return;
      }

      const userDoc = await getDoc(doc(db, 'userDetails', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data:', {
          profileUpdated: userData.profileUpdated,
          currentPath: location.pathname
        });
        
        // Show User Details in menu only if profile is not updated
        const updatedMenuItems = [...menuItems];
        if (!userData.profileUpdated) {
          updatedMenuItems.push({ name: 'User Details', href: '/dashboard/user-details', icon: FaUserCog });
        }
        setCurrentMenuItems(updatedMenuItems);

        // If profile not updated and not already on user-details page, redirect
        if (!userData.profileUpdated && location.pathname !== '/dashboard/user-details') {
          console.log('Redirecting to user-details: profile not updated');
          navigate('/dashboard/user-details');
          return;
        }

        // If profile is updated and on user-details page, redirect to dashboard
        if (userData.profileUpdated && location.pathname === '/dashboard/user-details') {
          console.log('Redirecting to dashboard: profile is updated');
          navigate('/dashboard');
          return;
        }

        // Make user-details the default page if profile not updated
        if (!userData.profileUpdated && location.pathname === '/dashboard') {
          console.log('Redirecting to user-details: on dashboard with unupdated profile');
          navigate('/dashboard/user-details');
        }
      } else {
        console.log('User document not found in Firestore');
      }
    };

    checkUserStatus();
  }, [navigate, location.pathname]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success('Logged out successfully');
      navigate('/login');
    } catch (error) {
      toast.error('Failed to logout');
    }
  };

  const sidebarVariants = {
    open: {
      x: 0,
      transition: { type: "spring", stiffness: 300, damping: 30 }
    },
    closed: {
      x: "-100%",
      transition: { type: "spring", stiffness: 300, damping: 30 }
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      {/* Overlay */}
      {isMobile && isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <AnimatePresence>
        {(isSidebarOpen || !isMobile) && (
          <motion.aside
            initial={isMobile ? "closed" : "open"}
            animate="open"
            exit="closed"
            variants={sidebarVariants}
            className={`fixed lg:relative w-64 h-full bg-white dark:bg-gray-800 shadow-lg z-30 transition-transform duration-300`}
          >
            {/* Sidebar Header */}
            <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
              <h2 className="text-xl font-semibold text-gray-800 dark:text-white">Dashboard</h2>
              {isMobile && (
                <button
                  onClick={() => setIsSidebarOpen(false)}
                  className="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <FaTimes className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                </button>
              )}
            </div>

            {/* Navigation Links */}
            <nav className="p-4">
              <ul className="space-y-2">
                {currentMenuItems.map((item) => {
                  const isActive = location.pathname === item.href;
                  return (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={`flex items-center p-2 rounded-lg transition-colors duration-200 ${
                          isActive
                            ? 'bg-blue-500 text-white'
                            : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                        }`}
                      >
                        <item.icon className="w-5 h-5 mr-3" />
                        <span>{item.name}</span>
                      </Link>
                    </li>
                  );
                })}
                <li>
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full p-2 text-gray-600 dark:text-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                  >
                    <FaSignOutAlt className="w-5 h-5 mr-3" />
                    <span>Logout</span>
                  </button>
                </li>
              </ul>
            </nav>
          </motion.aside>
        )}
      </AnimatePresence>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Header */}
        <header className="bg-white dark:bg-gray-800 shadow-sm">
          <div className="flex items-center justify-between p-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 rounded-md lg:hidden hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <FaBars className="w-6 h-6 text-gray-500 dark:text-gray-400" />
            </button>
          </div>
        </header>

        {/* Content Area */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900 p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
