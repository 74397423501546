import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase';
import MemberCard from '../../components/MemberCard';
import PageTitle from '../../components/PageTitle';
import { motion } from 'framer-motion';

interface Member {
  id: string;
  fullName: string;
  photo: string;
  memberType: string;
  rank: string;
  memberStatus: string;
  currentState: string;
  designation: string;
}

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const State = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedRank, setSelectedRank] = useState('President');

  const ranks = [
    'Select Designation',
    'President',
    'ADL President',
    'Senior Vice President',
    'Vice President',
    'Secretary',
    'General Secretary',
    'IT Incharge',
    'Special Officer',
    'Media Co-ordinator',
    'Legal Advisor',
    'Lifetime Member'
  ];

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true);
        let membersQuery;

        if (selectedRank === 'Select Designation') {
          membersQuery = query(
            collection(db, 'userDetails'),
            where('memberType', '==', 'State'),
            where('memberStatus', '==', 'Active'),
            where('designation', '==', 'President')
          );
        } else {
          membersQuery = query(
            collection(db, 'userDetails'),
            where('memberType', '==', 'State'),
            where('memberStatus', '==', 'Active'),
            where('designation', '==', selectedRank)
          );
        }
        
        const membersSnapshot = await getDocs(membersQuery);
        
        const membersData = membersSnapshot.docs
          .map(doc => ({
            id: doc.id,
            fullName: doc.data().fullName || 'Unknown',
            photo: doc.data().photo || '/default-avatar.png',
            memberType: doc.data().memberType || 'Member',
            rank: doc.data().rank || 'Member',
            memberStatus: doc.data().memberStatus || 'Active',
            currentState: doc.data().officeState || 'Unknown',
            designation: doc.data().designation || 'President'
          }))
          .sort((a, b) => a.fullName.localeCompare(b.fullName));

        setMembers(membersData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching members:', error);
        setLoading(false);
      }
    };

    fetchMembers();
  }, [selectedRank]);

  return (
    <div className="bg-gray-900 min-h-screen">
      <PageTitle
        title="State Members"
        subtitle="View all state level members"
      />

      <div className="container mx-auto px-4 py-8 sm:py-12">
        {/* Rank Selector */}
        <div className="max-w-xl mx-auto mb-8 sm:mb-12">
          <label htmlFor="rank-select" className="block text-sm sm:text-base text-gray-400 mb-2">
            Select Member Designation
          </label>
          <select
            id="rank-select"
            value={selectedRank}
            onChange={(e) => setSelectedRank(e.target.value)}
            className="block w-full px-3 sm:px-4 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
          >
            {ranks.map((rank) => (
              <option key={rank} value={rank === 'Select Designation' ? '' : rank}>
                {rank}
              </option>
            ))}
          </select>
        </div>

        {loading ? (
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          </div>
        ) : members.length === 0 ? (
          <div className="text-center text-gray-400 text-sm sm:text-base">
            No members found {selectedRank && selectedRank !== 'Select Designation' && 'for selected rank'}
          </div>
        ) : (
          <motion.div
            variants={container}
            initial="hidden"
            animate="visible"
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 sm:gap-8"
          >
            {members.map(member => (
              <MemberCard
                key={member.id}
                name={member.fullName}
                photoUrl={member.photo}
                memberType={`${member.memberType} ${member.designation || 'President'}`}
                rank={''}
                state={member.currentState}
              />
            ))}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default State;
