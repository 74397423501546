import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import centreImage from '../../assets/centre.jpg';
import rightImage from '../../assets/right.jpg';
import leftImage from '../../assets/left.jpg';

const slides = [
  {
    image: centreImage,
    title: 'Empowering Communities Through Research & Innovation',
    subtitle: 'Building a Safer Future',
    description: 'Leading the way in crime prevention and community safety through cutting-edge research and collaborative partnerships.',
    link: '/about',
    linkText: 'Discover More',
    position: 'center',
    titleWidth: 'max-w-5xl'
  },
  {
    image: rightImage,
    title: 'Promoting Peaceful Communities',
    subtitle: 'The Cost of Violence',
    description: 'Violence disrupts societal harmony, causes loss of life, and creates long-term trauma. Preventing violence is crucial for sustainable development.',
    link: '/research',
    linkText: 'Explore Research',
    position: 'right',
    titleWidth: 'max-w-3xl'
  },
  {
    image: leftImage,
    title: 'Empowering Citizens',
    subtitle: 'Corruption-Free and Violence-Free Society',
    description: 'Awareness campaigns and grassroots initiatives encourage citizens to speak out against corruption and violence.',
    link: '/community',
    linkText: 'Join Us',
    position: 'left',
    titleWidth: 'max-w-3xl'
  }
];

const HeroSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    const timer = setInterval(nextSlide, 7000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative -mt-[150px] h-screen w-full overflow-hidden bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-900 dark:to-gray-800">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ${
            currentSlide === index ? 'opacity-100 z-10' : 'opacity-0 z-0'
          }`}
        >
          <div
            className="absolute inset-0 bg-cover bg-center transition-transform duration-[2s] hover:scale-105"
            style={{ backgroundImage: `url(${slide.image})` }}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-black/30 to-black/40 dark:from-black/60 dark:via-black/50 dark:to-black/60" />
          </div>

          <div className={`relative flex h-full items-center ${
            slide.position === 'right' ? 'justify-end' : 
            slide.position === 'left' ? 'justify-start pl-8 lg:pl-16' : 'justify-center'
          }`}>
            <div className={`container mx-auto px-6 ${
              slide.position === 'center' ? 'flex justify-center' : 
              slide.position === 'right' ? 'flex justify-end' : ''
            }`}>
              <div 
                className={`${slide.titleWidth} ${
                  slide.position === 'right' ? 'text-right pr-8 lg:pr-16' : 
                  slide.position === 'left' ? 'mr-auto text-left' : 'text-center'
                }`}
              >
                <span className={`mb-4 inline-block rounded-full bg-blue-600/20 px-4 py-1 text-sm font-semibold text-blue-400 dark:text-blue-300 opacity-0 ${
                  slide.position === 'center' ? 'mx-auto' : 
                  slide.position === 'right' ? 'ml-auto' : ''
                } ${currentSlide === index ? (
                  slide.position === 'left' ? 'animate-fadeInLeft animation-delay-200' :
                  slide.position === 'right' ? 'animate-fadeInRight animation-delay-200' :
                  'animate-fadeInUp animation-delay-200'
                ) : ''}`}>
                  {slide.subtitle}
                </span>
                <h1 className={`mb-2 font-serif text-4xl font-bold leading-tight text-gray-900 dark:text-white md:text-5xl lg:text-6xl opacity-0 ${
                  currentSlide === index ? (
                    slide.position === 'left' ? 'animate-fadeInLeft animation-delay-400' :
                    slide.position === 'right' ? 'animate-fadeInRight animation-delay-400' :
                    'animate-fadeInUp animation-delay-400'
                  ) : ''
                }`}>
                  {slide.title}
                </h1>
                <p className={`mb-8 text-lg text-gray-700 dark:text-gray-300 md:text-xl lg:text-2xl opacity-0 ${
                  slide.position === 'center' ? 'mx-auto max-w-2xl' : 
                  slide.position === 'right' ? 'ml-auto max-w-2xl' : 'max-w-2xl'
                } ${currentSlide === index ? (
                  slide.position === 'left' ? 'animate-fadeInLeft animation-delay-600' :
                  slide.position === 'right' ? 'animate-fadeInRight animation-delay-600' :
                  'animate-fadeInUp animation-delay-600'
                ) : ''}`}>
                  {slide.description}
                </p>
                <div className={`opacity-0 ${
                  slide.position === 'center' ? 'text-center' : 
                  slide.position === 'right' ? 'text-right' : ''
                } ${currentSlide === index ? (
                  slide.position === 'left' ? 'animate-fadeInLeft animation-delay-800' :
                  slide.position === 'right' ? 'animate-fadeInRight animation-delay-800' :
                  'animate-fadeInUp animation-delay-800'
                ) : ''}`}>
                  <Link
                    to={slide.link}
                    className="group inline-flex items-center rounded-full bg-blue-600 dark:bg-blue-500 px-8 py-4 text-lg font-semibold text-white transition-all duration-300 hover:bg-blue-700 dark:hover:bg-blue-600 hover:shadow-lg hover:shadow-blue-600/30 dark:hover:shadow-blue-500/30"
                  >
                    {slide.linkText}
                    <FaArrowRight className="ml-2 transition-transform duration-300 group-hover:translate-x-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="absolute bottom-8 left-1/2 z-20 flex -translate-x-1/2 space-x-3">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`h-3 rounded-full transition-all duration-300 ${
              currentSlide === index
                ? 'w-12 bg-blue-500 dark:bg-blue-400'
                : 'w-3 bg-white/30 hover:bg-white/50 dark:bg-white/20 dark:hover:bg-white/40'
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroSlider;
