import React from "react";
import { FaUsers, FaChartLine, FaHandshake, FaLightbulb, FaQuoteLeft, FaArrowRight } from "react-icons/fa";
import PageTitle from '../../components/PageTitle';
import centreImage from '../../assets/centre.jpg';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';

interface Value {
  name: string;
  description: string;
  icon: React.ComponentType<any>;
  gradient: string;
}

const About: React.FC = () => {
  const heroRef = useScrollAnimation();
  const missionRef = useScrollAnimation();
  const valuesRef = useScrollAnimation();
  
  const values: Value[] = [
    {
      name: 'Research Excellence',
      description: 'Leading crime pattern analysis and preventive measures research',
      icon: FaChartLine,
      gradient: 'from-blue-500 to-cyan-400'
    },
    {
      name: 'Training Programs',
      description: 'Providing comprehensive security management and crime prevention workshops',
      icon: FaUsers,
      gradient: 'from-emerald-500 to-teal-400'
    },
    {
      name: 'Community Engagement',
      description: 'Implementing public safety awareness and neighborhood watch programs',
      icon: FaHandshake,
      gradient: 'from-purple-500 to-blue-400'
    },
    {
      name: 'Collaboration',
      description: 'Partnering with law enforcement, educational institutions, and NGOs',
      icon: FaLightbulb,
      gradient: 'from-amber-500 to-orange-400'
    }
  ];

  return (
    <div className="bg-gray-900">
      <PageTitle
        title="About CCRO"
        subtitle="Learn about our mission, values, and commitment to creating safer communities"
      />
      
      {/* Hero Section */}
      <div ref={heroRef} className="relative min-h-fit md:min-h-screen py-16 md:py-24 flex items-center overflow-hidden scroll-section">
        {/* Animated background */}
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-black">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_50%_-100%,#3b82f6,transparent)] opacity-20" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_80%_60%,#6366f1,transparent)] opacity-20" />
          </div>
        </div>

        {/* Floating shapes - Hidden on mobile */}
        <div className="absolute inset-0 overflow-hidden hidden md:block">
          <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-blue-500/10 rounded-full blur-3xl animate-blob" />
          <div className="absolute top-1/3 right-1/4 w-72 h-72 bg-indigo-500/10 rounded-full blur-3xl animate-blob animation-delay-2000" />
          <div className="absolute bottom-1/4 left-1/3 w-80 h-80 bg-cyan-500/10 rounded-full blur-3xl animate-blob animation-delay-4000" />
        </div>

        <div className="relative w-full z-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 items-center">
              {/* Content */}
              <div className="relative">
                <div className="absolute -left-4 -top-4 w-48 sm:w-72 h-48 sm:h-72 bg-gradient-to-br from-blue-600/20 to-transparent opacity-50 rounded-full blur-2xl animate-pulse" />
                <div className="relative space-y-4 sm:space-y-6">
                  <div className="inline-flex items-center space-x-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-blue-500/10 border border-blue-500/20 backdrop-blur-sm">
                    <span className="relative flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
                    </span>
                    <span className="text-xs sm:text-sm text-blue-200 font-medium">Crime Control and Research Organization</span>
                  </div>
                  
                  <h1 className="text-3xl sm:text-4xl lg:text-6xl font-bold tracking-tight text-white bg-clip-text animate-fade-in-up relative">
                    Creating Safer
                    <span className="block mt-2">Communities Together</span>
                    <div className="absolute -right-4 bottom-0 w-16 sm:w-24 h-16 sm:h-24 bg-gradient-to-tr from-blue-500 to-transparent opacity-20 rounded-full blur-xl animate-pulse delay-200" />
                  </h1>
                  
                  <p className="mt-4 sm:mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-blue-100 animate-fade-in-up delay-200 relative">
                    A registered non-profit organization dedicated to crime prevention and control through research, training, and community engagement. Working closely with law enforcement agencies, educational institutions, and community organizations.
                    <div className="absolute -left-4 -bottom-4 w-24 sm:w-32 h-24 sm:h-32 bg-gradient-to-br from-indigo-500 to-transparent opacity-20 rounded-full blur-xl animate-pulse delay-300" />
                  </p>
                  
                  <div className="mt-6 sm:mt-10 flex items-center gap-x-4 sm:gap-x-6 animate-fade-in-up delay-300">
                    <a href="#mission" className="group relative inline-flex items-center gap-x-2 rounded-full px-4 sm:px-6 py-2 sm:py-3 text-sm font-semibold text-white transition-all duration-300 hover:scale-105">
                      <span className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500 to-indigo-600 opacity-100 transition-opacity group-hover:opacity-90" />
                      <span className="relative">Discover Our Mission</span>
                      <FaArrowRight className="relative h-4 w-4 transition-transform group-hover:translate-x-1" />
                    </a>
                  </div>
                </div>
              </div>

              {/* Image */}
              <div className="relative mt-8 lg:mt-0 block">
                <div className="absolute -inset-4 bg-gradient-to-r from-blue-500 to-indigo-500 opacity-20 blur-3xl animate-pulse" />
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-tr from-blue-600/20 to-transparent opacity-50 rounded-2xl" />
                  <img
                    src={centreImage}
                    alt="Crime Research Center"
                    className="rounded-2xl shadow-2xl w-full h-auto object-cover transform transition-all duration-700 hover:scale-105 relative z-10"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-white/10 backdrop-blur-sm" />
                  
                  {/* Decorative elements - Hidden on mobile */}
                  <div className="absolute -top-6 -right-6 w-16 sm:w-24 h-16 sm:h-24 bg-gradient-to-br from-blue-500 to-indigo-500 opacity-20 rounded-full blur-xl animate-pulse hidden sm:block" />
                  <div className="absolute -bottom-8 -left-8 w-24 sm:w-32 h-24 sm:h-32 bg-gradient-to-tr from-cyan-500 to-blue-500 opacity-20 rounded-full blur-xl animate-pulse delay-200 hidden sm:block" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mission Statement */}
      <div ref={missionRef} id="mission" className="relative h-[500px] flex items-center bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 scroll-section">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
        </div>
        <div className="w-full relative">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-4xl">
              <div className="text-center transform transition-all duration-700">
                <span className="inline-flex items-center rounded-full px-4 py-1 text-sm font-medium bg-gray-800 text-gray-300 ring-1 ring-inset ring-gray-700 animate-fade-in-up">
                  Our Mission
                </span>
                <div className="relative mt-10 transform transition-all duration-700 hover:scale-105">
                  <FaQuoteLeft className="absolute -top-4 -left-4 h-8 w-8 text-gray-700 animate-fade-in" />
                  <blockquote className="relative text-2xl font-medium leading-10 text-white sm:text-3xl sm:leading-[3rem] animate-fade-in-up delay-200">
                    <p className="italic">
                      "To conduct research on crime patterns and prevention strategies, provide training and education on crime control measures, collaborate with law enforcement agencies, raise public awareness, and promote community safety initiatives."
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Core Values */}
      <div ref={valuesRef} className="relative min-h-fit md:min-h-screen py-16 md:py-24 flex items-center scroll-section">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-black">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_50%_-100%,#3b82f6,transparent)] opacity-20" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_80%_60%,#6366f1,transparent)] opacity-20" />
          </div>
        </div>
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:30px_30px] sm:bg-[size:60px_60px]" />
        <div className="w-full relative">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center mb-8 sm:mb-16 transform transition-all duration-700">
              <span className="inline-flex items-center rounded-full px-3 sm:px-4 py-1 text-xs sm:text-sm font-medium bg-blue-400/10 text-blue-200 ring-1 ring-inset ring-blue-400/20 animate-fade-in-up">
                Core Values
              </span>
              <h2 className="mt-6 sm:mt-8 text-2xl sm:text-3xl lg:text-4xl font-bold tracking-tight text-white animate-fade-in-up delay-100">
                What Drives Our Mission
              </h2>
              <p className="mt-4 sm:mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-blue-100 animate-fade-in-up delay-200">
                Our core values shape our approach to crime prevention and community safety
              </p>
            </div>

            <dl className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 lg:gap-8">
              {values.map((value, index) => (
                <div key={value.name} className="group relative animate-fade-in-up" style={{ animationDelay: `${index * 100}ms` }}>
                  <div className="relative h-full rounded-xl bg-white/10 p-4 sm:p-6 ring-1 ring-white/10 backdrop-blur-sm transition duration-500 group-hover:bg-white/20">
                    <div className="flex items-center justify-center w-10 sm:w-12 h-10 sm:h-12 rounded-lg bg-gradient-to-r mb-3 sm:mb-4 transform transition-all duration-500 group-hover:scale-110"
                         style={{ backgroundImage: `linear-gradient(to right, ${value.gradient})` }}>
                      <value.icon className="h-5 sm:h-6 w-5 sm:w-6 text-white" />
                    </div>
                    <dt className="text-base sm:text-lg font-semibold text-white">{value.name}</dt>
                    <dd className="mt-2 text-sm sm:text-base text-blue-100">{value.description}</dd>
                  </div>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;