import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { Toaster } from 'react-hot-toast';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import District from './pages/District';
import Download from './pages/Download/Download';
import Events from './pages/Events';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import National from './pages/National';
import Photo from './pages/Photo';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import Search from './pages/Search';
import State from './pages/State';
import VerifyEmail from './pages/VerifyEmail';
import Video from './pages/Video';
import DashboardLayout from './pages/Dashboard/Layout/DashboardLayout';
import Dashboard from './pages/Dashboard';
import Articles from './pages/Dashboard/Articles';
import NewArticle from './pages/Dashboard/NewArticle';
import EditArticle from './pages/Dashboard/EditArticle';
import QRCode from './pages/Dashboard/QRCode';
import DashboardDownload from './pages/Dashboard/Downloads';
import Notifications from './pages/Dashboard/Notifications';
import Support from './pages/Dashboard/Support';
import UserDetails from './pages/Dashboard/UserDetails';
import ProtectedRoute from './components/ProtectedRoute';

const AppContent = () => {
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith('/dashboard');

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-300">
      <Toaster
        position="top-right"
        toastOptions={{
          className: 'dark:bg-gray-800 dark:text-white',
          duration: 3000,
          style: {
            borderRadius: '10px',
            padding: '16px',
          },
        }}
      />
      {!isDashboardRoute && <Header />}
      <main className={`text-gray-900 dark:text-gray-100 ${!isDashboardRoute ? 'pt-24' : ''}`}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/district" element={<District />} />
          <Route path="/download" element={<Download />} />
          <Route path="/events" element={<Events />} />
          <Route path="/login" element={<Login />} />
          <Route path="/national" element={<National />} />
          <Route path="/photo" element={<Photo />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/search" element={<Search />} />
          <Route path="/state" element={<State />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/video" element={<Video />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* Protected Dashboard Routes */}
          <Route path="/dashboard" element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
            <Route index element={<Dashboard />} />
            <Route path="articles" element={<Articles />} />
            <Route path="new-article" element={<NewArticle />} />
            <Route path="edit-article" element={<EditArticle />} />
            <Route path="qr-code" element={<QRCode />} />
            <Route path="download" element={<DashboardDownload />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="support" element={<Support />} />
            <Route path="user-details" element={<UserDetails />} />
          </Route>
        </Routes>
      </main>
      {!isDashboardRoute && <Footer />}
    </div>
  );
};

function App() {
  return (
    <ThemeProvider>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;
