import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes, FaChevronDown } from "react-icons/fa";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    members: false,
    gallery: false,
  });
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = (key: "members" | "gallery") => {
    setDropdownOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setDropdownOpen({
          members: false,
          gallery: false,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Reset dropdowns when mobile menu is closed
  useEffect(() => {
    if (!open) {
      setDropdownOpen({
        members: false,
        gallery: false,
      });
    }
  }, [open]);

  const navItems = [
    { label: "Home", path: "/" },
    { label: "About", path: "/about" },
    { label: "Search", path: "/search" },
    {
      label: "Members",
      children: [
        { label: "National", path: "/national" },
        { label: "State", path: "/state" },
        { label: "District", path: "/district" },
      ],
    },
    { label: "Events", path: "/events" },
    {
      label: "Gallery",
      children: [
        { label: "Photo", path: "/photo" },
        { label: "Video", path: "/video" },
        { label: "Download", path: "/download" },
      ],
    },
    { label: "Contact", path: "/contact" },
  ];

  return (
    <header className="fixed left-0 top-0 z-50 w-full bg-[#1F2937]/50 backdrop-blur-sm">
      <div className="container mx-auto px-4 bg-transparent">
        <div className="relative flex h-16 sm:h-20 items-center justify-between">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <img
                src="/images/logo.png"
                alt="Logo"
                className="h-12 w-auto sm:h-16 object-contain"
              />
            </Link>
          </div>

          {/* Desktop Navigation and Auth Buttons - Hidden on Mobile */}
          <nav
            ref={menuRef}
            className="hidden lg:flex lg:items-center lg:justify-center lg:flex-1"
          >
            <ul className="flex items-center space-x-6">
              {navItems.map((item, index) => (
                <li key={index} className="relative">
                  {item.children ? (
                    <div className="relative">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(
                            item.label.toLowerCase() as "members" | "gallery"
                          );
                        }}
                        className="group flex items-center space-x-2 rounded-lg px-4 py-2 text-white transition-all duration-200 hover:bg-black/50 hover:backdrop-blur-sm"
                      >
                        <span>{item.label}</span>
                        <FaChevronDown
                          className={`transform transition-transform duration-300 ${
                            dropdownOpen[
                              item.label.toLowerCase() as "members" | "gallery"
                            ]
                              ? "rotate-180"
                              : ""
                          }`}
                        />
                      </button>
                      <div
                        className={`absolute left-0 z-50 min-w-[220px] rounded-lg bg-black/95 shadow-lg backdrop-blur-sm transition-all duration-300 ease-in-out ${
                          dropdownOpen[
                            item.label.toLowerCase() as "members" | "gallery"
                          ]
                            ? "visible translate-y-1 opacity-100"
                            : "invisible translate-y-0 opacity-0"
                        }`}
                      >
                        <div className="rounded-lg border border-white/10 p-2">
                          {item.children.map((child, childIndex) => (
                            <Link
                              key={childIndex}
                              to={child.path}
                              className="block rounded-lg px-4 py-2 text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
                              onClick={() => {
                                setTimeout(() => {
                                  setDropdownOpen({
                                    members: false,
                                    gallery: false,
                                  });
                                }, 100);
                              }}
                            >
                              {child.label}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to={item.path}
                      className="block rounded-lg px-4 py-2 text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
                    >
                      {item.label}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          {/* Desktop Auth Buttons - Hidden on Mobile */}
          <div className="hidden lg:flex lg:items-center lg:space-x-4">
            <Link
              to="/login"
              className="rounded-lg border-2 border-white/50 px-5 py-2 font-semibold text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
            >
              Login
            </Link>
            <Link
              to="/register"
              className="rounded-lg border-2 border-white/50 px-5 py-2 font-semibold text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
            >
              Register
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setOpen(!open)}
            className="lg:hidden inline-flex items-center justify-center rounded-md p-2 text-white hover:text-gray-300"
          >
            {open ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* Mobile Navigation Menu */}
          <div
            className={`lg:hidden fixed inset-0 z-50 transform ${
              open ? "translate-x-0" : "translate-x-full"
            } transition-transform duration-300 ease-in-out`}
          >
            <div className="absolute inset-0 bg-black/90 backdrop-blur-md">
              <div className="flex h-16 items-center justify-between px-4 border-b border-gray-800/50">
                <Link to="/" className="flex items-center" onClick={() => setOpen(false)}>
                  <img
                    src="/images/logo.png"
                    alt="Logo"
                    className="h-12 w-auto object-contain"
                  />
                </Link>
                <button
                  onClick={() => setOpen(false)}
                  className="rounded-md p-2 text-white hover:text-gray-300"
                >
                  <FaTimes size={24} />
                </button>
              </div>
              <div className="h-[calc(100vh-4rem)] overflow-y-auto px-4 pb-6 bg-black/90">
                <nav className="space-y-1">
                  {navItems.map((item, index) => (
                    <div key={index}>
                      {item.children ? (
                        <div>
                          <button
                            onClick={() =>
                              toggleDropdown(
                                item.label.toLowerCase() as "members" | "gallery"
                              )
                            }
                            className="flex w-full items-center justify-between rounded-lg px-4 py-3 text-lg font-medium text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
                          >
                            <span>{item.label}</span>
                            <FaChevronDown
                              className={`transform transition-transform duration-300 ${
                                dropdownOpen[
                                  item.label.toLowerCase() as "members" | "gallery"
                                ]
                                  ? "rotate-180"
                                  : ""
                              }`}
                            />
                          </button>
                          <div
                            className={`mt-2 space-y-1 pl-4 transition-all duration-300 ${
                              dropdownOpen[
                                item.label.toLowerCase() as "members" | "gallery"
                              ]
                                ? "max-h-96 opacity-100"
                                : "max-h-0 opacity-0 overflow-hidden"
                            }`}
                          >
                            {item.children.map((child, childIndex) => (
                              <Link
                                key={childIndex}
                                to={child.path}
                                className="block rounded-lg px-4 py-2 text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
                                onClick={() => {
                                  setOpen(false);
                                  setDropdownOpen({
                                    members: false,
                                    gallery: false,
                                  });
                                }}
                              >
                                {child.label}
                              </Link>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <Link
                          to={item.path}
                          className="block rounded-lg px-4 py-3 text-lg font-medium text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
                          onClick={() => setOpen(false)}
                        >
                          {item.label}
                        </Link>
                      )}
                    </div>
                  ))}
                </nav>
                {/* Mobile Auth Buttons */}
                <div className="mt-6 space-y-3">
                  <Link
                    to="/login"
                    className="flex w-full items-center justify-center rounded-lg border-2 border-white/50 px-5 py-2.5 font-semibold text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
                    onClick={() => setOpen(false)}
                  >
                    Login
                  </Link>
                  <Link
                    to="/register"
                    className="flex w-full items-center justify-center rounded-lg border-2 border-white/50 px-5 py-2.5 font-semibold text-white hover:bg-black/50 hover:backdrop-blur-sm transition-all duration-200"
                    onClick={() => setOpen(false)}
                  >
                    Register
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
