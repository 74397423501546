import React, { useState, useEffect } from 'react';
import { FaUsers, FaHandshake, FaShieldAlt } from 'react-icons/fa';
import HeroSlider from '../../components/HeroSlider';
import { useScrollAnimation } from '../../hooks/useScrollAnimation';
import { motion } from 'framer-motion';
import Photo from '../../assets/Photo.jpg';

const Home = () => {
  const missionRef = useScrollAnimation();
  const focusRef = useScrollAnimation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const teamMembers = [
    {
      name: "Dr. Rajesh Kumar",
      rank: "National President",
      image: Photo
    },
    {
      name: "Dr. Priya Singh",
      rank: "Vice President",
      image: Photo
    },
    {
      name: "Mr. Amit Patel",
      rank: "General Secretary",
      image: Photo
    },
    {
      name: "Mrs. Sneha Reddy",
      rank: "Joint Secretary",
      image: Photo
    },
    {
      name: "Dr. Vikram Shah",
      rank: "Research Director",
      image: Photo
    },
    {
      name: "Ms. Anita Desai",
      rank: "Training Head",
      image: Photo
    },
    {
      name: "Mr. Suresh Kumar",
      rank: "Community Outreach",
      image: Photo
    },
    {
      name: "Dr. Meera Iyer",
      rank: "Technical Advisor",
      image: Photo
    }
  ];

  const getVisibleMembers = () => {
    const members = [];
    for (let i = 0; i < 4; i++) {
      const index = (currentIndex + i) % teamMembers.length;
      members.push({
        ...teamMembers[index],
        position: i
      });
    }
    return members;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [teamMembers.length]);

  return (
    <div className="bg-gradient-to-b from-gray-900 via-gray-800 to-black min-h-screen">
      {/* Hero Section */}
      <div className="relative h-[90vh] sm:h-screen">
        <HeroSlider />
      </div>

      {/* Focus Areas - Interactive Grid */}
      <div ref={focusRef} className="relative w-full min-h-fit py-16 md:min-h-screen md:py-24 overflow-x-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-black">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_50%_-100%,#3b82f6,transparent)] opacity-20" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_80%_60%,#6366f1,transparent)] opacity-20" />
          </div>
        </div>

        <div className="relative w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 md:pt-0">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-8 sm:mb-12 lg:mb-16"
          >
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white mb-4 sm:mb-6">Key Focus Areas</h2>
            <div className="w-20 sm:w-24 h-1 bg-gradient-to-r from-purple-500 to-pink-500 mx-auto mb-4 sm:mb-6"></div>
            <p className="text-base sm:text-lg lg:text-xl text-gray-300 max-w-3xl mx-auto px-4">
              Driving innovation in crime prevention through research, technology, and community engagement
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 lg:gap-8 max-w-6xl mx-auto">
            {[
              {
                title: "Research & Development",
                items: ["Crime Pattern Analysis", "Preventive Measures", "Social Impact Studies", "Technology Integration"],
                gradient: "from-blue-600 to-cyan-500"
              },
              {
                title: "Training Programs",
                items: ["Prevention Workshops", "Security Management", "Community Safety", "Youth Initiatives"],
                gradient: "from-purple-600 to-pink-500"
              },
              {
                title: "Community Engagement",
                items: ["Public Awareness", "Neighborhood Watch", "School Programs", "Women's Safety"],
                gradient: "from-emerald-600 to-teal-500"
              },
              {
                title: "Technology Solutions",
                items: ["Data Analytics", "Predictive Models", "Security Systems", "Digital Safety"],
                gradient: "from-amber-600 to-orange-500"
              }
            ].map((area, index) => (
              <motion.div
                key={area.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="group relative overflow-hidden rounded-xl sm:rounded-2xl bg-gray-800/30 hover:bg-gray-800/40 transition-colors duration-300"
              >
                {/* Fixing template string expression */}
                <div className={`absolute inset-0 bg-gradient-to-br ${area.gradient} opacity-10 group-hover:opacity-20 transition-opacity duration-300`} />
                <div className="relative p-4 sm:p-6 lg:p-8">
                  {/* Fixing template string expression */}
                  <h3 className={`text-xl sm:text-2xl font-bold text-white mb-4 sm:mb-6 group-hover:text-transparent bg-clip-text bg-gradient-to-r ${area.gradient} transition-colors duration-300`}>
                    {area.title}
                  </h3>
                  <div className="grid grid-cols-1 gap-3 sm:gap-4">
                    {area.items.map((item, i) => (
                      <motion.div
                        key={i}
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: i * 0.1 }}
                        className="flex items-center space-x-3 group/item"
                      >
                        <div className={`h-2 w-2 rounded-full bg-gradient-to-r ${area.gradient} group-hover/item:scale-125 transition-transform duration-300`} />
                        <span className="text-sm sm:text-base text-gray-300 group-hover/item:text-white transition-colors duration-300">
                          {item}
                        </span>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Mission Section - Modern Cards */}
      <div ref={missionRef} className="relative min-h-[600px] py-16 sm:py-24 overflow-hidden flex items-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-6 sm:mb-8"
          >
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white mb-4 sm:mb-6">Our Mission</h2>
            <div className="w-20 sm:w-24 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mx-auto"></div>
          </motion.div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: FaShieldAlt,
                title: "Prevention First",
                description: "Leading research in crime prevention strategies and pattern analysis",
                color: "from-blue-500 to-cyan-400"
              },
              {
                icon: FaUsers,
                title: "Community Focus",
                description: "Building stronger, safer communities through education and engagement",
                color: "from-purple-500 to-pink-400"
              },
              {
                icon: FaHandshake,
                title: "Collaborative Approach",
                description: "Partnering with law enforcement and security agencies",
                color: "from-emerald-500 to-teal-400"
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="group relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-gray-800/50 to-gray-900/50 rounded-2xl transform transition-all duration-300 group-hover:scale-[1.02]" />
                <div className="relative p-8 rounded-2xl backdrop-blur-sm border border-gray-700/50">
                  <div className={`inline-flex p-4 rounded-xl bg-gradient-to-r ${item.color} mb-6 transform transition-all duration-300 group-hover:scale-110`}>
                    <item.icon className="h-8 w-8 text-white" />
                  </div>
                  <h3 className="text-2xl font-bold text-white mb-4">{item.title}</h3>
                  <p className="text-gray-300 leading-relaxed">{item.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* National Members Team Section */}
      <div className="relative min-h-screen py-16 overflow-hidden flex items-center justify-center">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-gray-900 to-black">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_20%_0%,#3b82f6,transparent)] opacity-20" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_80%_20%,#6366f1,transparent)] opacity-10" />
          </div>
        </div>

        <div className="relative w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-8"
          >
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-4 md:mb-6">National Members Team</h2>
            <div className="w-20 md:w-24 h-1 bg-gradient-to-r from-blue-500 to-indigo-500 mx-auto mb-6 md:mb-8"></div>
            <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto px-4">
              Meet our dedicated team of professionals working towards a safer tomorrow
            </p>
          </motion.div>

          <div className="relative w-full max-w-6xl mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
              {getVisibleMembers().map((member, index) => (
                <div
                  key={`${currentIndex}-${index}`}
                  className="group relative aspect-[4/5] overflow-hidden rounded-2xl bg-gray-800"
                >
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 to-transparent opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300" />
                  
                  <div className="absolute inset-0 bg-gradient-to-br from-gray-800 to-gray-900">
                    <div className="absolute inset-0 opacity-80 group-hover:opacity-100 transition-opacity duration-300">
                      <img
                        src={member.image}
                        alt={member.name}
                        className="h-full w-full object-cover object-center transform group-hover:scale-110 transition-transform duration-500"
                      />
                    </div>
                  </div>

                  <div className="absolute inset-0 flex flex-col justify-end p-4 md:p-6">
                    <div className="transform backdrop-blur-sm bg-black/50 p-3 md:p-4 rounded-lg translate-y-0 sm:translate-y-[200%] sm:group-hover:translate-y-0 transition-all duration-300 ease-out">
                      <h3 className="text-lg md:text-xl font-bold text-white mb-1 opacity-100">
                        {member.name}
                      </h3>
                      <p className="text-xs md:text-sm text-gray-300 opacity-100">
                        {member.rank}
                      </p>
                    </div>
                  </div>

                  <div className="absolute inset-0 border-2 border-transparent group-hover:border-blue-500/50 rounded-2xl transition-colors duration-300" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
