import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaDownload, FaEye, FaCalendar } from 'react-icons/fa';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../../../config/firebase';
import { toast } from 'react-hot-toast';

interface Download {
  id: string;
  title: string;
  description: string;
  fileUrl: string;
  fileType: string;
  fileSize: string;
  createdAt: any;
  downloadCount: number;
}

const Downloads = () => {
  const [downloads, setDownloads] = useState<Download[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDownloads();
  }, []);

  const fetchDownloads = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const downloadsRef = collection(db, 'downloads');
      const q = query(
        downloadsRef,
        where('userId', '==', user.uid)
      );

      const querySnapshot = await getDocs(q);
      const downloadsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Download[];

      setDownloads(downloadsList);
    } catch (error) {
      console.error('Error fetching downloads:', error);
      toast.error('Failed to load downloads');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (download: Download) => {
    try {
      const response = await fetch(download.fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = download.title;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast.success('Download started successfully!');
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file');
    }
  };

  const formatDate = (timestamp: any) => {
    if (!timestamp) return '';
    try {
      // If it's a Firestore Timestamp
      if (timestamp && typeof timestamp.toDate === 'function') {
        return timestamp.toDate().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      }
      // If it's a string, try to parse it
      const date = new Date(timestamp);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
    >
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
          Downloads
        </h1>

        {downloads.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500 dark:text-gray-400">
              No downloads available yet.
            </p>
          </div>
        ) : (
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {downloads.map((download) => (
              <motion.div
                key={download.id}
                whileHover={{ scale: 1.02 }}
                className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6 shadow-sm"
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-2">
                    <span className="text-lg font-semibold text-gray-900 dark:text-white">
                      {download.title}
                    </span>
                  </div>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    download.fileType === 'pdf' ? 'bg-red-100 text-red-800' :
                    download.fileType === 'doc' ? 'bg-blue-100 text-blue-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {download.fileType.toUpperCase()}
                  </span>
                </div>

                <p className="text-gray-600 dark:text-gray-300 text-sm mb-4">
                  {download.description}
                </p>

                <div className="flex items-center justify-between text-sm text-gray-500 dark:text-gray-400 mb-4">
                  <div className="flex items-center space-x-2">
                    <FaCalendar className="w-4 h-4" />
                    <span>{formatDate(download.createdAt)}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaEye className="w-4 h-4" />
                    <span>{download.downloadCount} downloads</span>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {download.fileSize}
                  </span>
                  <button
                    onClick={() => handleDownload(download)}
                    className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    <FaDownload className="w-4 h-4" />
                    <span>Download</span>
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default Downloads;
